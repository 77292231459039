import React from "react";
import { ReactComponent as TwitterBrand } from "../../img/twitter-brand.svg";
import { ReactComponent as IconArticle } from "@material-design-icons/svg/filled/article.svg";
import { ReactComponent as RocketLaunch } from "@material-design-icons/svg/filled/rocket_launch.svg";
import { ReactComponent as PlayCircle } from "@material-design-icons/svg/filled/play_circle.svg";
import { ReactComponent as IconPlayerCards } from "../../img/icon-player-cards.svg";
import { ReactComponent as IconCup } from "@material-design-icons/svg/filled/emoji_events.svg";
import DotBg from "../../img/Dot pattern full.png";
import { StoreButtons } from "../util/stores";
import { Button } from "../util/button";
import { withUser } from "../../userContext";
import { useTailwindMediaQueries } from "../util/mediaQueries";

function TweetAction(props) {
  return (
    <div
      className={
        "w-full flex flex-row gap-1 self-center justify-end text-end lg:text-start lg:justify-start 1.5xl:justify-end 1.5xl:text-end hover:opacity-80 "
      }
    >
      <div className={"w-4 h-4 self-center"}>
        <TwitterBrand className={"w-full h-full fill-primary"} />
      </div>
      <p className={"text-primary font-semibold text-sm justify-end whitespace-nowrap"}>{props.action}</p>
    </div>
  );
}

function ArticleAction(props) {
  const action = props.action || "Read article";
  return (
    <div
      className={
        "w-full flex flex-row gap-1 self-center justify-end text-end lg:text-start lg:justify-start 1.5xl:justify-end 1.5xl:text-end hover:opacity-80"
      }
    >
      <div className={"w-5 h-5 self-center"}>
        <IconArticle className={"w-full h-full fill-primary"} />
      </div>
      <p className={"text-primary font-semibold text-sm justify-end whitespace-nowrap"}>{action}</p>
    </div>
  );
}

function BuildLineupAction(props) {
  const action = props.action || "Build your lineups";
  return (
    <div
      className={
        "w-full flex flex-row gap-1 self-center justify-end text-end lg:text-start lg:justify-start 1.5xl:justify-end 1.5xl:text-end hover:opacity-80"
      }
    >
      <div className={"w-5 h-5 self-center"}>
        <IconPlayerCards className={"w-full h-full fill-primary"} />
      </div>
      <p className={"text-primary font-semibold text-sm justify-end whitespace-nowrap"}>{action}</p>
    </div>
  );
}

function VideoAction(props) {
  return (
    <div
      className={
        "w-full flex flex-row gap-1 self-center justify-end text-end lg:text-start lg:justify-start 1.5xl:justify-end 1.5xl:text-end hover:opacity-80"
      }
    >
      <div className={"w-5 h-5 self-center"}>
        <PlayCircle className={"w-full h-full fill-primary"} />
      </div>
      <p className={"text-primary font-semibold text-sm justify-end lg:justify-start 1.5xl:justify-end whitespace-nowrap"}>
        {props.action ? props.action : "Watch video"}
      </p>
    </div>
  );
}

function HomeNews(props) {
  return (
    <div
      className={`w-full h-full flex flex-col items-center sm:flex-row gap-2 p-2 lg:flex-col 1.5xl:flex-row self-center justify-center sm:justify-between lg:justify-center 1.5xl:justify-between ${
        props.border ? "border-t border-transparent-inverse-surface-low border-opacity-10" : ""
      }`}
    >
      <div className={"flex"}>
        <p className={"w-full font-medium font-headers text-sm self-end"}>{props.text}</p>
      </div>
      <div className={"flex"}>
        <a
          href={props.target}
          target={"_blank"}
          className={"self-center text-end flex w-full justify-end lg:justify-start 1.5xl:justify-end"}
          rel="noreferrer"
        >
          {props.action}
        </a>
      </div>
    </div>
  );
}

const HomeBanner = withUser((props) => {
  const { value } = props;
  const { banner } = value;
  const backgroundImage = useBannerImageClasses(banner?.backgroundImages);
  return (
    <div className={"flex flex-col sm:flex-row justify-between lg:flex-row flex-col space-y-4 lg:space-y-0 lg:space-x-6"}>
      <div
        style={{ backgroundImage: backgroundImage }}
        className={"flex w-full lg:w-8/12 bg-no-repeat bg-center bg-cover rounded-lg h-home-banner-xs 1.5xl:h-home-banner-1.5xl"}
      >
        <div className={"w-full px-6 py-4 flex flex-col justify-start items-start gap-4"}>
          <div className={"w-full xl:w-6/12"}>
            <p className={"text-white font-semibold text-3xl"}>{banner?.title}</p>
          </div>
          <div className={"w-full xl:w-6/12 space-y-5"}>
            <p className={"font-headers font-medium text-sm lg:text-base 3xl:text-lg text-white"}>{banner?.subtitle}</p>
            {banner?.subtitle2 && (
              <p className={"font-headers font-medium text-sm lg:text-base 3xl:text-lg text-white"}>{banner.subtitle2}</p>
            )}
          </div>
          <div className={"h-[17%] flex self-star pb-2 flex-row xl:justify-start"}>
            <div className={"flex flex-row gap-4 font-headers font-medium text-white h-full"}>
              <CTA value={banner?.callToAction} />
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          "flex flex-col w-full lg:w-4/12 relative rounded-lg bg-surface-container px-6 py-6 lg:h-home-banner-xs 1.5xl:h-home-banner-1.5xl text-on-surface"
        }
      >
        <div
          className={"absolute h-[65px] w-3/12 top-0 right-0 flex flex-row justify-end self-end"}
          style={{
            backgroundImage: "url('" + DotBg + "')",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <p className={"font-semibold text-3xl font-headers pb-3"}>Latest news</p>
        <div className={"grid grid-cols-1 grid-rows-3 h-full"}>
          {value.latestNews?.map((news) => {
            const action = news.callToAction;
            const cta = action.type;
            const actionElem =
              cta === "READ_ARTICLE" ? (
                <ArticleAction />
              ) : cta === "BUILD_YOUR_LINEUPS" ? (
                <BuildLineupAction />
              ) : cta === "WATCH_VIDEO" ? (
                <VideoAction />
              ) : (
                <ArticleAction />
              );
            return <HomeNews key={news.title} border={true} text={news.title} target={action.link || ""} action={actionElem} />;
          })}
        </div>
      </div>
    </div>
  );
});

const useBannerImageClasses = (bannerImages) => {
  const mdQueries = useTailwindMediaQueries();
  if (!bannerImages) {
    return "";
  }
  if (mdQueries["5xl"]) return `url(${bannerImages["5xl"]})`;
  if (mdQueries["3xl"]) return `url(${bannerImages["3xl"]})`;
  if (mdQueries["2xl"]) return `url(${bannerImages["2xl"]})`;
  if (mdQueries["1.5xl"]) return `url(${bannerImages["1.5xl"]})`;
  if (mdQueries.xl) return `url(${bannerImages.xl})`;
  if (mdQueries.lg) return `url(${bannerImages.lg})`;
  if (mdQueries.md) return `url(${bannerImages.md})`;
  if (mdQueries.sm) return `url(${bannerImages.sm})`;
  if (mdQueries.xs) return `url(${bannerImages.xs})`;
  return `url(${bannerImages["5xl"]})`;
};

const CTA = (props) => {
  if (!props.value) {
    return null;
  }
  const { type, link } = props.value;
  if (type === "DOWNLOAD_APP") {
    return <StoreButtons />;
  }
  const icon =
    type === "READ_ARTICLE" ? (
      <IconArticle className={"w-4 h-4 fill-on-primary"} />
    ) : type === "WATCH_VIDEO" ? (
      <PlayCircle className={"w-4 h-4 fill-on-primary"} />
    ) : type === "BUILD_YOUR_LINEUPS" ? (
      <IconPlayerCards className={"w-4 h-4 fill-on-primary"} />
    ) : (
      <RocketLaunch className={"w-4 h-4 fill-on-primary"} />
    );
  const text =
    type === "READ_ARTICLE"
      ? "Read article"
      : type === "WATCH_VIDEO"
      ? "Watch video"
      : type === "BUILD_YOUR_LINEUPS"
      ? "Build your lineups"
      : "Get started";
  return (
    <a href={link || ""} target={"_blank"} rel="noreferrer">
      <Button context="primary" icon={icon}>
        {text}
      </Button>
    </a>
  );
};

export default HomeBanner;
