import React, { useEffect, useState } from "react";
import AuctionWrapper from "../auctions/auctionWrapper";
import HomeDashboard from "./homeDashboard";
import RisingPlayers from "./risingPlayers";
import GLOBAL_MARGIN from "../util/margin";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import HomeBanner from "./bannerBox";
import { ReactComponent as BarChart } from "@material-design-icons/svg/round/equalizer.svg";
import HomeAnnouncement from "./homeAnnouncement";

function Home(props) {
  document.title = "Home";
  const [dashboard, setDashboard] = useState({});
  const [homeHeader, setHomeHeader] = useState({});
  const [risingPlayers, setRisingPlayers] = useState({});
  const [mostSoldPlayers, setMostSoldPlayers] = useState({});
  const [isPromo, setIsPromo] = useState(false);

  const getHomeHeader = () => {
    props
      .fetch("/apiv2/homeHeader")
      .then((response) => response.json())
      .then((res) => {
        setHomeHeader(res);
      })
      .catch(errorCatcher());
  };

  const getHomeDashboard = () => {
    props
      .fetch("/apiv2/stats/homeDashboard")
      .then((response) => response.json())
      .then((res) => {
        setDashboard(res.home_dashboard);
        setMostSoldPlayers(res.most_sold_players);
        setRisingPlayers(res.rising_and_falling_players);
      })
      .catch(errorCatcher());
  };

  useEffect(() => {
    var endPromo = new Date(1675814400000).getTime();
    var now = new Date().getTime();
    console.log(endPromo, now);
    if (now < endPromo) {
      console.log("Promo");
      setIsPromo(true);
    }
  }, []);

  useEffect(() => {
    getHomeHeader();
    getHomeDashboard();
  }, []);

  return (
    <div className={GLOBAL_MARGIN + " flex flex-col gap-2 my-6 text-on-surface"}>
      <div>
        <HomeBanner value={homeHeader} />
      </div>
      <div>
        <div className={"flex flex-row justify-between items-center"}>
          <h2 className={"text-2xl my-3 text-primary"}>Market volumes</h2>
          <a href={"/stats"} className={"block"}>
            <p className={"text-primary text-sm flex flex-row gap-1 items-center font-semibold hover:opacity-80"}>
              <BarChart className={"fill-primary"} />
              <span>View charts</span>
            </p>
          </a>
        </div>
        <HomeDashboard dashboard={dashboard} />
      </div>

      {mostSoldPlayers?.most_sold_week && risingPlayers?.rising_players && (
        <div>
          <h2 className={"text-2xl my-3 text-primary"}>Market trends</h2>
          <div>
            <RisingPlayers mostSoldPlayers={mostSoldPlayers} risingPlayers={risingPlayers} />
          </div>
        </div>
      )}

      <div>
        <h2 className={"text-2xl my-3 text-primary"}>Next auctions</h2>
        <div>
          <AuctionWrapper tmp={"next"} refresh={45000} filter={true} loadMore={false} />
        </div>
      </div>
      <HomeAnnouncement />
    </div>
  );
}

export default withUser(Home);
