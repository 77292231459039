import { errorCatcher } from "../util/errors";
import { withUser } from "../../userContext";
import SubscriptionBox from "./subscriptionBox";
import ManageSubscriptionBox from "./managementSubscriptionBox";
import React, { useEffect, useState } from "react";
import { isStripeAllowed } from "../util/handleSubscriptionTier";
import Info from "../../img/icon-info-on-surface.svg";
import { format, parseISO } from "date-fns";

function GetSubscription(props) {
  const [plan, setPlan] = useState({});
  const [reload, setReload] = useState(Date.now());

  useEffect(() => {
    getSubscription();
  }, [reload]);

  const deleteSubscription = () => {
    props
      .fetch("/apiv2/stripe/subscriptions/cancelNow", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .then((res) => {
        setReload(Date.now());
      })
      .catch(errorCatcher());
  };

  const getSubscription = () => {
    props
      .fetch("/apiv2/stripe/subscriptions/schedule", {
        method: "GET",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => response.json())
      .then((res) => {
        setPlan(res);
      })
      .catch(errorCatcher());
  };

  function formattedDate(dateString) {
    const endDate = parseISO(dateString);

    if (isNaN(endDate)) {
      console.error("Invalid date string:", dateString);
      return "Invalid Date";
    }

    return format(endDate, "MMMM do yyyy");
  }

  return (
    <div className="flex flex-col p-4 gap-y-4 md:gap-y-8">
      <h2 className={"text-primary"}>Membership</h2>

      <div className="flex flex-col space-y-4 max-w-[45em]">
        {isStripeAllowed(plan.store) && (
          <>
            <SubscriptionBox
              isAutoRenewable={plan.is_auto_renewable}
              plan={plan.current_plan}
              amount={plan.current_price / 100}
              amount_no_discount={plan.current_price_no_discount !== undefined ? plan.current_price_no_discount / 100 : undefined}
              currency={plan.current_currency}
              end={plan.current_end_date}
              renewal={!plan.cancel_at_current_end}
              period={plan.current_frequency}
              trial={plan.is_current_trial}
              nextPlan={plan.next_plan}
              nextAmount={plan.next_price / 100}
              customerBalance={plan.customer_balance / 100}
              nextPeriod={plan.next_frequency}
              cancel={plan.cancel_at_current_end}
              cancelDate={plan.current_end_date}
              planCanceledByUs={plan.canceled_by_us}
              reload={setReload}
              isFeatureDisabled={plan.is_feature_disabled}
            />

            {plan.current_frequency !== plan.next_frequency && plan.next_plan !== "" && (
              <div className="w-full flex flex-col lg:flex-row space-y-2 lg:space-y-0 items-center justify-center space-x-4 bg-white p-4 shadow rounded-xl">
                <img src={Info} alt="" width="24" height="24" />

                <p className="text-sm">
                  You have requested a change in your subscription that will be applied on {formattedDate(plan.current_end_date)}
                </p>
              </div>
            )}
          </>
        )}

        {!isStripeAllowed(plan.store) && (
          <div className="w-full flex flex-col lg:flex-row space-y-2 lg:space-y-0 items-center justify-center space-x-4 bg-white p-4 shadow rounded-xl">
            <img src={Info} alt="" width="24" height="24" />

            <p className="text-sm">Your membership was activated through our mobile app, please submit any changes there.</p>
          </div>
        )}

        {plan.user_verified !== undefined && !plan.user_verified && (
          <div className="w-full flex flex-col lg:flex-row space-y-2 lg:space-y-0 items-center justify-center space-x-4 bg-white p-4 shadow rounded-xl border border-[#06BBA3]">
            <img src={Info} alt="" width="24" height="24" />

            <p className="text-sm">
              Please verify your email address to be able to upgrade your plan or receive notifications. Check your email or{" "}
              <a href={"/profile?target=email"} className={"text-brand-text cursor-pointer"}>
                resend a new one.
              </a>
            </p>
          </div>
        )}
      </div>

      {isStripeAllowed(plan.store) && (
        <ManageSubscriptionBox
          plan={plan.current_plan}
          nextPlan={plan.next_plan}
          currentFrequency={plan.current_frequency}
          nextFrequency={plan.next_frequency}
          currentCurrency={plan.current_currency}
          nextCurrency={plan.next_currency}
          cancel={plan.cancel_at_current_end}
          cancelDate={plan.current_end_date}
          reload={setReload}
          isFeatureDisabled={plan.is_feature_disabled}
        />
      )}
      {/* {plan.current_plan && plan.current_plan !== "free" &&
                <div className="flex flex-col w-full max-w-[40em] min-h-[15em] p-4 items-center justify-center bg-white rounded-lg">
                    <label className="text-lg text-red-700">For developement Use</label>
                    <div className="flex">
                        <Button label={"Delete membership"} onClick={() => {
                        deleteSubscription();
                        }} />
                    </div>
                </div>
            } */}
    </div>
  );
}

export default withUser(GetSubscription);
