import { useMediaQuery } from "@react-hook/media-query";
import { useMemo } from "react";

export const useTailwindMediaQueries = () => {
  const xs = useMediaQuery("(max-width: 639px)");
  const sm = useMediaQuery("(min-width: 640px)");
  const md = useMediaQuery("(min-width: 768px)");
  const lg = useMediaQuery("(min-width: 1024px)");
  const xl = useMediaQuery("(min-width: 1280px)");
  const oneAndHalfXl = useMediaQuery("(min-width: 1400px)");
  const twoXl = useMediaQuery("(min-width: 1536px)");
  const twoAndHalfXl = useMediaQuery("(min-width: 1660px)");
  const threeXl = useMediaQuery("(min-width: 1920px)");
  const threeAndHalfXl = useMediaQuery("(min-width: 2240px)");
  const fourXl = useMediaQuery("(min-width: 2560px)");
  const fourAndHalfXl = useMediaQuery("(min-width: 2880px)");
  return useMemo(() => {
    return {
      xs,
      sm,
      md,
      lg,
      xl,
      "1.5xl": oneAndHalfXl,
      "2xl": twoXl,
      "2.5xl": twoAndHalfXl,
      "3xl": threeXl,
      "3.5xl": threeAndHalfXl,
      "4xl": fourXl,
      "4.5xl": fourAndHalfXl,
    };
  }, [xs, sm, md, lg, xl, oneAndHalfXl, twoXl, twoAndHalfXl, threeXl, threeAndHalfXl, fourXl, fourAndHalfXl]);
};
