import React, { useEffect, useState } from "react";
import PlayerRankingLine from "./playerRankingLine";
import InfiniteScroll from "react-infinite-scroll-component";
import { withUser } from "../../userContext";
import PlayerRankingHeaderLine from "./playerRankingHeaderLine";
import { withJSONPagination } from "../util/pagination";
import GreenButton from "../util/greenButton";
import { errorCatcher } from "../util/errors";
import { isFree } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import { sorareBaseball, sorareBasketball } from "../util/sports";
import SDLoading from "../util/SDLoading";
import { currentBaseballSeason, currentBasketballSeason } from "../util/seasons";
import { SportNotSupported } from "../util/sportNotSupported";

function PlayerRankingNewLook(props) {
  const limitAPI = 100;
  const [ranking, setRanking] = useState({ list: [], offset: 0 });
  const [sort, setSort] = useState({ field: "avg20", order: -1 });
  const [title1, setTitle1] = useState("PA/G");
  const [title2, setTitle2] = useState("wOBA");
  const [title3, setTitle3] = useState("AVG");
  const [value1, setValue1] = useState("avg");
  const [value2, setValue2] = useState("woba");
  const [value3, setValue3] = useState("bavg");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getRanking(true, { field: "avg20", order: -1 });
  }, [props.sport]);

  const getRanking = (reset, s) => {
    if (reset) {
      setRanking({ list: [], offset: 0 });
    }
    let leagueValue = props.league;
    if (leagueValue === "All leagues") leagueValue = "all";
    let scoringValue = "new";
    if (!props.newMatrix) {
      scoringValue = "old";
    }

    if (!s) s = sort;

    if (props.minApps >= 0 && props.minApps <= 100 && props.user.sorareSlug !== undefined && props.sport !== undefined) {
      setLoading(true);
      const params = new URLSearchParams({});
      params.append("u23", props.u23);
      params.append("position", props.position.toLowerCase());
      params.append("league", leagueValue);
      if (props.eligibilityCompetitions) {
        Object.keys(props.eligibilityCompetitions).forEach((c) => {
          params.append("competitionsEligible", c);
        });
      }

      params.append("minApps", props.minApps);
      params.append("gw", props.gw || "");
      params.append("sport", props.sport);
      params.append("scoring", scoringValue);
      params.append("inGallery", props.galleryFilter);
      params.append("sort", s.field + "," + (s.order > 0 ? "asc" : "desc"));
      props
        .fetch(`/apiv2/stats/players/ranking?${params.toString()}`, { headers: withJSONPagination(reset ? 0 : ranking.offset, limitAPI) })
        .then((response) => response.json())
        .then((res) => {
          setRanking({
            list: reset ? res : [...ranking.list, ...res],
            offset: (reset ? 0 : ranking.offset) + limitAPI,
          });
          setSort(s);

          let isHitters = !props.position.includes("Pitcher");
          if (!isHitters) {
            setTitle1("IP");
            setTitle2("ERA");
            setTitle3("FIP");
            setValue1("ip");
            setValue2("era");
            setValue3("fip");
          } else {
            setTitle1("PA/G");
            setTitle2("wOBA");
            setTitle3("AVG");
            setValue1("apg");
            setValue2("woba");
            setValue3("bavg");
          }
          setLoading(false);
        })
        .catch(errorCatcher())
        .finally(() => setLoading(false));
    }
  };

  const sortDataNew = (field) => {
    let newSort = { ...sort, field };
    if (sort.field === field) {
      newSort.order = -1 * newSort.order; // reverse order
    } else {
      newSort.order = -1; // desc order
    }
    setSort(newSort);
    getRanking(true, newSort);
  };

  if (props.sport === sorareBasketball || props.sport === sorareBaseball) {
    return <SportNotSupported sport={props.sport} featureName="Player rankings" />;
  } else {
    return (
      <div>
        <div className={"text-center"}>
          <GreenButton onClick={() => getRanking(true)} label={"Filter"} />
        </div>
        {!!ranking && !!ranking.list && (
          <InfiniteScroll
            dataLength={ranking.list.length}
            style={{ overflowY: "hidden" }}
            className={"space-y-2 xl:w-11/12 mx-4 xl:mx-auto mt-8"}
            hasMore={true}
            next={() => {
              getRanking(false);
            }}
          >
            {isFree(props.user.tier) && (
              <UpgradeLimitBox
                className={"bg-white bg-upgrade-bg-player-rankings bg-cover h-upgrade-banner-s w-full"}
                title={"Want more insights?"}
                description={"Become a Star member to unlock players status, average score breakdown and premium filters."}
              />
            )}
            <PlayerRankingHeaderLine newMatrix={props.newMatrix} sort={sortDataNew} showBmp={props.showBmp} showValo={props.showValo} />
            {ranking.list.map((r, i) => {
              return (
                <PlayerRankingLine
                  key={r.player.PlayerId}
                  sport={props.sport}
                  newMatrix={props.newMatrix}
                  ranking={r}
                  rank={i + 1}
                  showBmp={props.showBmp}
                  showValo={props.showValo}
                />
              );
            })}
            <div className="space-y-2 xl:w-11/12 mx-4 xl:mx-auto mt-8">{loading && <SDLoading />}</div>
          </InfiniteScroll>
        )}
      </div>
    );
  }
}

export default withUser(PlayerRankingNewLook);
