import { useRef } from "react";
import { withUser } from "../../userContext";
import Logo from "../../img/logo-transparent-small.svg";
import format from "date-fns/format";
import { useHistory } from "react-router-dom";
import { formatPrice } from "../util/formatMoney";
import { Button } from "../util/button";
import React, { Fragment, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { errorCatcher } from "../util/errors";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { membershipLabel } from "../util/handleSubscriptionTier";
import InfoCircleBlackIcon from "../../img/icons-circle-info-black.svg";
import IconRocket from "../../img/icon-rocket.svg";
import IconRocketBlack from "../../img/icon-rocket-black.svg";
import IconSettings from "../../img/icon-settings.svg";
import IconAutoRenew from "../../img/icon-auto-renew.svg";
import IconReset from "../../img/icon-reset.svg";
import IconSwap from "../../img/icon-swap.svg";
import IconHelp from "../../img/icon-help-rounded.svg";
import IconCancel from "../../img/icon-event-busy.svg";
import { MobileModale } from "../util/mobileModale";
import { useTailwindMediaQueries } from "../util/mediaQueries";

function SubscriptionBox(props) {
  const mdQueries = useTailwindMediaQueries();
  const history = useHistory();
  const [openDoNotRenewModale, setOpenDoNotRenewModale] = useState(false);
  const [openSettingsMenu, setOpenSettingsMenu] = useState(false);

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenSettingsMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const removePendingCancel = () => {
    props
      .fetch("/apiv2/stripe/subscriptions/removePendingCancel", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({}),
      })
      .then((response) => response.json())
      .then(() => {
        ReactTooltip.rebuild();
        props.reload(Date.now());
      })
      .catch(errorCatcher());
  };

  const onDoNotRenew = (reason, comment) => {
    props
      .fetch("/apiv2/stripe/subscriptions/cancel", {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain, */*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reason: reason || "",
          comment: comment || "",
        }),
      })
      .then((response) => response.json())
      .then((res) => {
        console.log(res);
        ReactTooltip.rebuild();
        props.reload(Date.now());
      })
      .catch(errorCatcher());
  };

  if (props.plan === "free") {
    return (
      <div className="p-8 bg-cover w-full max-w-[60em] bg-[#06BBA3] bg-no-repeat bg-center shadow-sm rounded-lg justify-self-auto justify-items-center text-center bg-subscription-t1 text-sm">
        <div className="grid grid-cols-4 grid-rows-9 w-full h-full place-items-center text-1.5xl-sm">
          <div className="row-span-1 col-span-2 row-start-1 col-start-2">
            <img src={Logo} />
          </div>
          <div className="mt-6 col-span-4 row-start-2 font-semibold">Membership</div>
          <div className="mb-6 col-span-4 row-start-3">Rookie</div>
        </div>
        <div>
          <Button disabled={props.isFeatureDisabled} onClick={() => history.push("/product")}>
            <img src={IconRocket} alt="" width="18" height="18" />

            <span>Upgrade membership</span>
          </Button>
        </div>
      </div>
    );
  } else if (props.plan === "tier1" || props.plan === "tier2") {
    const freeTier2WithTier1Plan = props.user.tier === "tier2" && props.plan === "tier1";
    const wasOnAutoRenewAndRenewalWasCanceledByUs = props.planCanceledByUs === true;
    const displayRenewal = wasOnAutoRenewAndRenewalWasCanceledByUs || props.renewal;

    return (
      <div
        className={`text-sm relative w-full p-8 pt-4 bg-center bg-cover shadow-sm rounded-lg justify-self-auto justify-items-center text-center text-white 
        ${props.plan === "tier1" && !freeTier2WithTier1Plan ? "bg-[#06BBA3] bg-subscription-t1" : "bg-[#292929] bg-subscription-t2"}`}
      >
        <div className="flex flex-col w-full h-full justify-center place-items-center text-1.5xl-sm gap-y-4">
          <div className="flex items-center w-full pl-[24px]">
            <div className="flex items-center justify-center flex-grow">
              <img src={Logo}></img>
            </div>

            <div className="relative inline-block" ref={ref}>
              <button className="relative z-10" onClick={() => setOpenSettingsMenu(!openSettingsMenu)}>
                <img src={IconSettings} alt="" width="24" height="24" />
              </button>

              <MobileModale open={!mdQueries["md"] && openSettingsMenu} close={() => setOpenSettingsMenu(false)}>
                <div className={"bg-surface-container text-on-surface rounded-t-2xl text-left text-sm"}>
                  <ul>
                    <li className="p-4 pb-1 text-base font-semibold">Manage membership</li>
                    {!props.isFeatureDisabled &&
                      !(props.period === "year" && props.nextPeriod === "month" && props.cancel) &&
                      props.period !== "year" && (
                        <li className="p-4 border-b border-outline-variant last:border-none">
                          <button className="flex items-center space-x-4 font-medium" onClick={() => history.push("/product")}>
                            <img src={IconRocketBlack} alt="" width="18" height="18" />
                            <span>Extend membership</span>
                          </button>
                        </li>
                      )}
                    {!props.isFeatureDisabled && props.cancel && props.isAutoRenewable && (
                      <li className="p-4 border-b border-outline-variant last:border-none">
                        <button className="flex items-center space-x-4 font-medium" onClick={() => removePendingCancel(props)}>
                          <img src={IconAutoRenew} alt="" width="18" height="18" />
                          <span>Activate renewal</span>
                        </button>
                      </li>
                    )}
                    {!props.isFeatureDisabled && props.period === "year" && props.nextPeriod === "month" && (
                      <li className="p-4 border-b border-outline-variant last:border-none">
                        <button
                          className="flex items-center space-x-4 font-medium"
                          onClick={() => {
                            removePendingCancel(props);
                            setOpenSettingsMenu(false);
                          }}
                        >
                          <img src={IconReset} alt="" width="18" height="18" />
                          <span>Reset to Yearly membership</span>
                        </button>
                      </li>
                    )}
                    {!props.isFeatureDisabled && !(props.period === "year" && props.nextPeriod === "month") && props.period === "year" && (
                      <li className="p-4 border-b border-outline-variant last:border-none">
                        <button className="flex items-center space-x-4 font-medium" onClick={() => history.push("/product")}>
                          <img src={IconSwap} alt="" width="18" height="18" />
                          <span>Change membership</span>
                        </button>
                      </li>
                    )}
                    <li className="p-4 border-b border-outline-variant last:border-none">
                      <a
                        href="mailto:support@soraredata.com"
                        className="flex items-center space-x-4 font-medium"
                        onClick={() => {
                          setOpenSettingsMenu(false);
                        }}
                      >
                        <img src={IconHelp} alt="" width="18" height="18" />
                        <span>Contact support</span>
                      </a>
                    </li>
                    {!props.isFeatureDisabled && !props.cancel && (
                      <li className="p-4 border-b border-outline-variant last:border-none">
                        <button
                          className="flex items-center space-x-4 font-medium text-[#CC0000]"
                          onClick={() => {
                            setOpenDoNotRenewModale(true);
                            setOpenSettingsMenu(false);
                          }}
                        >
                          <img src={IconCancel} alt="" width="18" height="18" />
                          <span>Do not renew membership</span>
                        </button>
                      </li>
                    )}
                    <li className="p-4 border-b border-outline-variant last:border-none">
                      <button className="flex items-center space-x-4 font-medium" onClick={() => setOpenSettingsMenu(false)}>
                        Close
                      </button>
                    </li>
                  </ul>
                </div>
              </MobileModale>

              {openSettingsMenu && mdQueries.md && (
                <div
                  className="absolute top-full right-0 mt-2 bg-surface-container-high text-on-surface rounded-md min-w-[280px]"
                  style={{ boxShadow: "0px 2px 6px 2px rgba(0, 0, 0, 0.15), 0px 1px 2px 0px rgba(0, 0, 0, 0.30)" }}
                >
                  <ul>
                    {!props.isFeatureDisabled &&
                      !(props.period === "year" && props.nextPeriod === "month" && props.cancel) &&
                      props.period !== "year" && (
                        <li className="p-4 border-b border-outline-variant last:border-none">
                          <button className="flex items-center space-x-4 font-medium" onClick={() => history.push("/product")}>
                            <img src={IconRocketBlack} alt="" width="18" height="18" />
                            <span>Extend membership</span>
                          </button>
                        </li>
                      )}
                    {!props.isFeatureDisabled && props.cancel && props.isAutoRenewable && (
                      <li className="p-4 border-b border-outline-variant last:border-none">
                        <button className="flex items-center space-x-4 font-medium" onClick={() => removePendingCancel(props)}>
                          <img src={IconAutoRenew} alt="" width="18" height="18" />
                          <span>Activate renewal</span>
                        </button>
                      </li>
                    )}
                    {!props.isFeatureDisabled && props.period === "year" && props.nextPeriod === "month" && (
                      <li className="p-4 border-b border-outline-variant last:border-none">
                        <button
                          className="flex items-center space-x-4 font-medium"
                          onClick={() => {
                            removePendingCancel(props);
                            setOpenSettingsMenu(false);
                          }}
                        >
                          <img src={IconReset} alt="" width="18" height="18" />
                          <span>Reset to Yearly membership</span>
                        </button>
                      </li>
                    )}
                    {!props.isFeatureDisabled && !(props.period === "year" && props.nextPeriod === "month") && props.period === "year" && (
                      <li className="p-4 border-b border-outline-variant last:border-none">
                        <button className="flex items-center space-x-4 font-medium" onClick={() => history.push("/product")}>
                          <img src={IconSwap} alt="" width="18" height="18" />
                          <span>Change membership</span>
                        </button>
                      </li>
                    )}
                    <li className="p-4 border-b border-outline-variant last:border-none">
                      <a
                        href="mailto:support@soraredata.com"
                        className="flex items-center space-x-4 font-medium"
                        onClick={() => {
                          setOpenSettingsMenu(false);
                        }}
                      >
                        <img src={IconHelp} alt="" width="18" height="18" />
                        <span>Contact support</span>
                      </a>
                    </li>
                    {!props.isFeatureDisabled && !props.cancel && (
                      <li className="p-4 border-b border-outline-variant last:border-none">
                        <button
                          className="flex items-center space-x-4 font-medium text-[#CC0000]"
                          onClick={() => {
                            setOpenDoNotRenewModale(true);
                            setOpenSettingsMenu(false);
                          }}
                        >
                          <img src={IconCancel} alt="" width="18" height="18" />
                          <span>Do not renew membership</span>
                        </button>
                      </li>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>

          {mdQueries.lg && (
            <div>
              <div className={"font-semibold mb-2"}>Membership</div>
              {freeTier2WithTier1Plan ? (
                <div className={"space-x-1"}>
                  <span className={"line-through text-[#7C7C7C]"}>Pro</span>
                  <span>Star (free upgrade) 🆙</span>
                </div>
              ) : props.plan === "tier1" ? (
                <div>Pro</div>
              ) : (
                <div>Star</div>
              )}
            </div>
          )}

          <div className={"w-full flex flex-col gap-y-3"}>
            <div className={`w-full grid justify-evenly gap-y-3 ${displayRenewal && mdQueries.lg ? "grid-cols-3" : "grid-cols-2"}`}>
              {!mdQueries.md && (
                <div className={"flex flex-col justify-center items-center text-center w-full"}>
                  <div className="font-semibold mb-2">Membership</div>
                  {freeTier2WithTier1Plan ? (
                    <div className={"space-x-1"}>
                      <span className={"line-through text-[#7C7C7C]"}>Pro</span>
                      <span>Star (free upgrade) 🆙</span>
                    </div>
                  ) : props.plan === "tier1" ? (
                    <div>Pro</div>
                  ) : (
                    <div>Star</div>
                  )}
                </div>
              )}

              <div className={"flex flex-col justify-center items-center text-center w-full"}>
                <div className="font-semibold mb-2">Current Membership</div>
                {props.trial ? (
                  <div>Free Trial</div>
                ) : (
                  <div>
                    {props.amount_no_discount === undefined ||
                    props.amount_no_discount === 0 ||
                    props.amount === props.amount_no_discount ? (
                      `${formatPrice(props.amount, props.currency, undefined, 2)} per ${props.period}`
                    ) : (
                      <span className={"flex flex-row gap-1"}>
                        <span className={"line-through"}>{`${formatPrice(props.amount_no_discount, props.currency, undefined, 2)}`}</span>
                        <span>{`${formatPrice(props.amount, props.currency, undefined, 2)} per ${props.period}`}</span>
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className={"w-full flex flex-col justify-center text-center"}>
                <div className="font-semibold mb-2">{props.renewal ? "Next Renewal Date" : "Expiration"}</div>
                <div>{format(new Date(props.end), "MMMM do yyyy")}</div>
              </div>
              {displayRenewal && (
                <div className={"w-full flex flex-col justify-center text-center"}>
                  <div className="font-semibold mb-2">Renewal Method</div>
                  <div>
                    {wasOnAutoRenewAndRenewalWasCanceledByUs ? (
                      <div className={"space-x-1"}>
                        <span className={"line-through text-[#7C7C7C]"}>Automatic</span>
                        <span>Offer expired</span>
                      </div>
                    ) : (
                      "Automatic"
                    )}
                  </div>
                </div>
              )}
            </div>
            <div
              className={`w-full grid justify-evenly ${
                (props.plan !== props.nextPlan || props.trial) && props.renewal && props.customerBalance < 0 ? "grid-cols-2" : "grid-cols-1"
              }`}
            >
              {(props.plan !== props.nextPlan || props.trial) && props.renewal && (
                <div className={"flex flex-col justify-center text-center"}>
                  <div className="font-semibold mb-2">Membership at Renewal Date</div>
                  <div>
                    {formatPrice(props.nextAmount, props.currency, undefined, 2)} per {props.nextPeriod}
                  </div>
                </div>
              )}
              {props.customerBalance < 0 && (
                <div className={"flex flex-col justify-center text-center"}>
                  <div className="font-semibold mb-2">Outstanding discounts to be applied</div>
                  <div>{formatPrice(props.customerBalance, props.currency, undefined, 2)}</div>
                </div>
              )}
            </div>
          </div>
        </div>

        <DoNotRenewModal
          reload={props.reload}
          open={openDoNotRenewModale}
          cancelDate={props.cancelDate}
          close={() => setOpenDoNotRenewModale(false)}
          onCancel={onDoNotRenew}
        />
      </div>
    );
  } else {
    return <div></div>;
  }
}

const cancelReason = {
  no_longer_playing_sorare: "I will no longer be playing Sorare",
  no_longer_using_soraredata: "I will no longer be using SorareData",
  financial_reasons: "Financial reasons",
  rookie_is_enough: "Rookie (free) membership is enough for my usage",
  not_satisfied_with_value: "I am not satisfied with the value provided by #TIERCURRENT# membership",
  want_to_change_membership: "I want to switch to another membership",
  want_to_change_periodicity: "I want to change my current membership periodicity",
  only_planned_one_month: "I only planned to subscribe for 1 month",
  forgot_to_cancel: "I forgot to cancel after my trial period",
  other: "Other",
};

const maxCommentLength = 300;

const DoNotRenewModal = withUser((props) => {
  const { cancelDate, onCancel } = props;
  const [open, setOpen] = useState(false);
  const [reason, setReason] = useState("");
  const [comment, setComment] = useState("");

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  if (open) {
    const commentIsRequired = reason === "other";
    const isCommentValid = (commentIsRequired ? comment.length > 0 : comment >= 0) && comment.length <= maxCommentLength;
    const canSubmit = reason !== "" && isCommentValid;
    return (
      <div>
        <Transition.Root show={open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-50 inset-0 overflow-y-auto"
            onClose={() => {
              props.close();
              setOpen(false);
            }}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-full-black bg-opacity-80 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-grey-f8 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
                  <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-grey-f8 rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        props.close();
                        setOpen(false);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className={"flex flex-col gap-1"}>
                    <h3 className={"font-headers text-xl m-0"}>Membership auto-renew removal</h3>
                    <p className="font-semibold text-xs">
                      You may loose some premium features you&apos;re already enjoying as watchlists, price alerts, and more. For help,
                      reach out to our support team:{" "}
                      <a href={"mailto:support@soraredata.com"} className={"text-super-rare underline hover:opacity-80"}>
                        support@soraredata.com
                      </a>
                    </p>
                    <div className={"bg-background-grey rounded-lg p-3 mt-3 text-sm flex flex-col gap-3"}>
                      <p>Please select your reason for stopping your membership:</p>
                      <ul className={"flex flex-col gap-1"}>
                        {Object.entries(cancelReason).map(([key, value]) => {
                          const selected = reason === key;
                          const canClick = !selected;
                          const displayHelp =
                            selected &&
                            (key === "not_satisfied_with_membership" ||
                              key === "want_to_change_membership" ||
                              key === "want_to_change_periodicity");
                          return (
                            <li
                              key={key}
                              className={`flex flex-col gap-1 ${canClick && "cursor-pointer"}`}
                              onClick={canClick ? () => setReason(key) : undefined}
                            >
                              <div className={"flex flex-row gap-2 items-center"}>
                                <div
                                  className={`rounded-full cursor-pointer bg-white ${
                                    selected ? "h-4 w-4 border-brand border-5" : "h-4 w-4 border-brand-black border"
                                  }`}
                                />
                                <span className={`${selected ? "text-brand" : ""}`}>
                                  {value.replace("#TIERCURRENT#", membershipLabel(props.user.tier))}
                                </span>
                              </div>
                              {displayHelp && (
                                <p className={"text-xs ml-10 italic"}>
                                  Instead of canceling, you can{" "}
                                  <a href={"/product?downgradable=true"} className={"text-super-rare underline hover:opacity-80"}>
                                    downgrade or upgrade your membership
                                  </a>
                                </p>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                      <div className={"flex flex-col gap-1"}>
                        <div className={"flex flex-row justify-between text-xs"}>
                          <p className={"text-grey-66 font-semibold"}>
                            Additional comment
                            {commentIsRequired && <span className={"text-horrible-red"}>*</span>}
                          </p>
                          <p className={`italic ${isCommentValid ? "text-textGrey3" : "text-horrible-red"}`}>
                            {comment?.length || 0}/{maxCommentLength}
                          </p>
                        </div>
                        <div className={"bg-white rounded-lg p-1 flex flex-row gap-2"}>
                          <textarea
                            value={comment}
                            onChange={(v) => setComment(v.target.value)}
                            className={"border-0 w-full text-sm"}
                            placeholder={"Explain the reason of your cancellation or a message to the team"}
                          />
                        </div>
                      </div>
                      <div className={"bg-white rounded-lg p-2 flex flex-row gap-2 items-center"}>
                        <img src={InfoCircleBlackIcon} className={"h-6 w-6 opacity-50"} />
                        <p>Your subscription will stop on {format(new Date(cancelDate), "MMMM do yyyy")}</p>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-center gap-2 mt-3">
                    <Button
                      context="secondary"
                      label={"Stay subscribed"}
                      onClick={() => {
                        props.close();
                        setOpen(false);
                      }}
                    />
                    <Button
                      label={"Proceed with cancellation"}
                      type="submit"
                      disabled={!canSubmit}
                      onClick={
                        canSubmit
                          ? () => {
                              onCancel && onCancel(reason, comment);
                              props.close();
                              setOpen(false);
                            }
                          : undefined
                      }
                    />
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </div>
    );
  } else {
    return null;
  }
});

export default withUser(SubscriptionBox);
