export const durations_before_end_options = [
  { value: "5m0s", name: "5 min before auction ends" },
  { value: "15m0s", name: "15 min before auction ends" },
  { value: "30m0s", name: "30 min before auction ends" },
  { value: "when_auction_is_listed", name: "When auction is listed" },
];

export const seasons = [
  { value: "IN_SEASON", name: "New Season" },
  { value: "CLASSIC", name: "Classic" },
  { value: "2025", name: "2025" },
  { value: "2024", name: "2024" },
  { value: "2023", name: "2023" },
  { value: "2022", name: "2022" },
  { value: "2021", name: "2021" },
  { value: "2020", name: "2020" },
  { value: "2019", name: "2019" },
  { value: "2018", name: "2018" },
];

export const editions = [
  { value: "serial_1", name: "Serial" },
  { value: "jersey_mint", name: "Jersey Mint" },
  { value: "special", name: "Special" },
];

export const listing_types = [
  { value: "offer", name: "Live offers" },
  { value: "auction", name: "Auctions" },
];

export const price_type_floor = "floor";
export const price_type_custom = "custom";
export const price_type_last_avg = "last_avg";

export const accepted_currencies = [
  { value: "all", name: "All" },
  { value: "FIAT", name: "FIAT only" },
  { value: "ETH", name: "ETH only" },
];
