/**
 * @deprecated should use /cards/seasons route
 */
export const seasons_objects = [
  { value: "2025", name: "2025" },
  { value: "2024", name: "2024" },
  { value: "2023", name: "2023" },
  { value: "2022", name: "2022" },
  { value: "2021", name: "2021" },
  { value: "2020", name: "2020" },
  { value: "2019", name: "2019" },
  { value: "2018", name: "2018" },
];

/**
 * @deprecated should use /cards/seasons route
 */
export const seasons_objects_with_all = [
  { value: "all", name: "All seasons" },
  { value: "2025", name: "2025" },
  { value: "2024", name: "2024" },
  { value: "2023", name: "2023" },
  { value: "2022", name: "2022" },
  { value: "2021", name: "2021" },
  { value: "2020", name: "2020" },
  { value: "2019", name: "2019" },
  { value: "2018", name: "2018" },
];

/**
 * @deprecated should use /cards/seasons route
 */
export const seasons_objects_int = [
  { value: 2025, name: "2025" },
  { value: 2024, name: "2024" },
  { value: 2023, name: "2023" },
  { value: 2022, name: "2022" },
  { value: 2021, name: "2021" },
  { value: 2020, name: "2020" },
  { value: 2019, name: "2019" },
  { value: 2018, name: "2018" },
];

/**
 * @deprecated should use /cards/seasons route
 */
export const seasons_objects_int_with_all = [
  { value: 0, name: "All seasons" },
  { value: 2025, name: "2025" },
  { value: 2024, name: "2024" },
  { value: 2023, name: "2023" },
  { value: 2022, name: "2022" },
  { value: 2021, name: "2021" },
  { value: 2020, name: "2020" },
  { value: 2019, name: "2019" },
  { value: 2018, name: "2018" },
];

export const seasons_types_objects_with_all = [
  { value: "", name: "All seasons cards" },
  { value: "CLASSIC", name: "Classic" },
  { value: "IN_SEASON", name: "In-Season" },
];

export const currentBaseballSeason = 2024;
export const currentBasketballSeason = 2023;
