import React, { useEffect, useState } from "react";
import { ReactTooltip } from "../util/tooltip.js";
import ImpactPlayer from "../games/impactPlayer";
import { all_positions_objects } from "../util/positions";
import GLOBAL_MARGIN from "../util/margin";
import InfiniteScroll from "react-infinite-scroll-component";
import SDLoading from "../util/SDLoading";
import SelectSearch from "react-select-search";
import { withUser } from "../../userContext";
import { errorCatcher } from "../util/errors";
import { isFree } from "../util/handleSubscriptionTier";
import UpgradeLimitBox from "../util/upgradeLimitBox";
import { sorareBaseball, sorareBasketball, sorareFootball } from "../util/sports";
import { SelectWithIcon } from "../util/select";
import { SportNotSupported } from "../util/sportNotSupported";

function SO5ImpactPlayers(props) {
  const [impact, setImpact] = useState({});
  const [divisions, setDivisions] = useState([]);
  const [position, setPosition] = useState("all");
  const [divisionOptions, setDivisionOptions] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState("");
  const [displayedPlayers, setDisplayedPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [playerFilter, setPlayerFilter] = useState("");
  const [sortingOption, setSortingOption] = useState("power");

  const sport = props.sport;

  useEffect(() => {
    getDivisions();
  }, [props.gwNumber]);

  const getDivisions = () => {
    props
      .fetch("/apiv2/gw/divisionsFull/" + props.gwNumber + "?sport=" + sport)
      .then((response) => response.json())
      .then((res) => {
        setDivisions(res);
        let options = [];
        res.map((d) => {
          if (sport === sorareBasketball) {
            options.push({ value: d.divisionId, name: d.displayName });
          } else {
            if (d.Enabled) {
              options.push({ value: d.DivisionId, label: d.divisionFullDisplayName, icon: d.LogoUrl });
            }
          }
        });
        setDivisionOptions(options);
        setSelectedDivision(options[0].value);
        getImpactPlayers(options[0].value);
      })
      .catch(errorCatcher());
  };

  const getImpactPlayers = (d, p) => {
    if (sport === sorareBasketball) {
      let divisionValue = selectedDivision;
      if (d !== undefined) divisionValue = d;
      props
        .fetch("/apiv2/SO5/basketballImpactPlayers?gw=" + props.gwNumber + "&divisionId=" + divisionValue)
        .then((response) => response.json())
        .then((res) => {
          res.impact_players = res.impact_players || [];
          setImpact(res);
          setFilteredPlayers(res.impact_players);
          setDisplayedPlayers(res.impact_players.slice(0, 50));
          searchPlayer(playerFilter, res.impact_players);
        })
        .catch(errorCatcher());
    } else {
      let divisionValue = selectedDivision;
      if (d !== undefined) divisionValue = d;
      let positionValue = position;
      if (p !== undefined) positionValue = p;
      props
        .fetch("/apiv2/SO5/impactPlayers/" + divisionValue + "/" + positionValue + "/" + props.gwNumber)
        .then((response) => response.json())
        .then((res) => {
          res.impact_players = res.impact_players || [];
          setImpact(res);
          setFilteredPlayers(res.impact_players);
          setDisplayedPlayers(res.impact_players.slice(0, 50));
          searchPlayer(playerFilter, res.impact_players);
        })
        .catch(errorCatcher());
    }
  };

  const searchPlayer = (p, ip) => {
    let basePlayers = impact.impact_players;
    if (ip !== undefined) basePlayers = ip;
    let newPlayers = [];
    basePlayers.map((c) => {
      if (c.player.DisplayName.toLowerCase().includes(p.toLowerCase())) {
        newPlayers.push(c);
      }
    });
    setFilteredPlayers(newPlayers);
    setDisplayedPlayers(newPlayers.slice(0, 50));
    sortData(sortingOption, newPlayers);
  };

  const sortData = (w, ip) => {
    let newPlayers = [...filteredPlayers];
    if (ip !== undefined) {
      newPlayers = [...ip];
    }
    if (w === "power") {
      if (sport === sorareBasketball) {
        newPlayers.sort(function (a, b) {
          if (a.stats?.totalPower > b.stats?.totalPower) {
            return -1;
          } else if (a.stats?.totalPower < b.stats?.totalPower) {
            return 1;
          } else return 0;
        });
      } else {
        newPlayers.sort(function (a, b) {
          if (a.stats.total_power > b.stats.total_power) {
            return -1;
          } else if (a.stats.total_power < b.stats.total_power) {
            return 1;
          } else return 0;
        });
      }
    } else if (w === "score") {
      if (sport === sorareBasketball) {
        newPlayers.sort(function (a, b) {
          if (a.gameStats?.score > b.gameStats?.score) {
            return -1;
          } else if (a.gameStats?.score < b.gameStats?.score) {
            return 1;
          } else return 0;
        });
      } else {
        newPlayers.sort(function (a, b) {
          if (a.game_stats.stats.SO5Score > b.game_stats.stats.SO5Score) {
            return -1;
          } else if (a.game_stats.stats.SO5Score < b.game_stats.stats.SO5Score) {
            return 1;
          } else return 0;
        });
      }
    }
    setFilteredPlayers(newPlayers);
    setDisplayedPlayers(newPlayers.slice(0, 50));
  };

  const onMore = () => {
    setDisplayedPlayers(filteredPlayers.slice(0, displayedPlayers.length + 50));
  };

  const handleChangeDivision = (selectedOption) => {
    if (selectedOption) {
      setSelectedDivision(selectedOption.value);
      getImpactPlayers(selectedOption.value);
    }
  };

  if (sport === sorareBasketball || sport === sorareBaseball) {
    return <SportNotSupported sport={sport} featureName="Impact players" />;
  }

  return (
    <div className={"space-y-8 mt-8 " + GLOBAL_MARGIN}>
      <div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-4"}>
        <div className={"z-20"}>
          <SelectWithIcon
            options={divisionOptions}
            selectedValue={selectedDivision}
            onChange={handleChangeDivision}
            containerClasses={"rounded"}
          />
        </div>
        {sport === sorareFootball && (
          <div className={"z-20"}>
            <SelectSearch
              options={all_positions_objects}
              closeOnSelect={true}
              value={position}
              onChange={(v) => {
                setPosition(v);
                getImpactPlayers(undefined, v);
              }}
            />
          </div>
        )}
        <div className={"z-20"}>
          <SelectSearch
            options={[
              { name: "Sort by power", value: "power" },
              { name: "Sort by score", value: "score" },
            ]}
            closeOnSelect={true}
            multiple={false}
            value={sortingOption}
            onChange={(v) => {
              setSortingOption(v);
              sortData(v);
            }}
          />
        </div>
        <input
          className="focus:outline-none block w-full py-2 rounded-md pl-4 shadow sm:text-sm border-grey-e5"
          value={playerFilter}
          placeholder={"Filter by player name"}
          onChange={(v) => {
            searchPlayer(v.target.value);
            setPlayerFilter(v.target.value);
          }}
        />
      </div>
      <InfiniteScroll
        next={onMore}
        hasMore={displayedPlayers.length < filteredPlayers.length}
        loader={<SDLoading />}
        dataLength={displayedPlayers.length}
      >
        <div className={"grid grid-cols-1 md:grid-cols-2 3xl:grid-cols-3 gap-x-4 gap-y-2"}>
          {displayedPlayers.map((p) => {
            return (
              <div className={"bg-white rounded-lg"} key={p.PlayerId}>
                <ImpactPlayer player={p} sport={sport} gw={impact.division_stats} />
              </div>
            );
          })}
        </div>
        {isFree(props.user.tier) && sport === sorareFootball && (
          <div className="my-4">
            <UpgradeLimitBox
              className={"bg-white bg-upgrade-bg-impact bg-cover h-upgrade-banner-s w-full"}
              title={"Want more insights?"}
              description={"Become a Star member to unlock the impact players tool."}
            />
          </div>
        )}
      </InfiniteScroll>

      <ReactTooltip />
    </div>
  );
}

export default withUser(SO5ImpactPlayers);
