import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";
import useComponentVisible from "../util/outsideAlerter";
import BgNavBar from "../../img/bg-nav-bar.png";
import SportsSoccerIcon from "../../img/sports_soccer.svg";
import SportsBaseballIcon from "../../img/sports_baseball.svg";
import SportsBasketballIcon from "../../img/sports_basketball.svg";
import AvatarIcon from "../../img/icon-avatar.svg";
import { ChevronRightIcon } from "@heroicons/react/solid";
import BlueBgMembership from "../../img/blue-bg-membership^.png";
import { isTier2, MembershipBadge, t1OrBelow } from "../util/handleSubscriptionTier";
import { SideBarUserFooter, ThemeButton } from "./navbarComponents";

export function NewTag() {
  return <div className="rounded-full bg-tertiary-fixed text-on-tertiary-fixed text-xs font-semibold px-1 py-0.5">New</div>;
}

function SidebarItem(props) {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

  const onClickLink = () => {
    if (props.link) {
      props.history.push(props.link);
    } else if (props.menu) {
      setIsComponentVisible(!isComponentVisible);
    }
  };

  let opacity = " hover:bg-white hover:bg-opacity-8";
  if (isComponentVisible) {
    opacity = "bg-white bg-opacity-14";
  }

  let inactiveClass = !props.noSports ? "flex cursor-pointer justify-center rounded-full text-focus " + opacity : "";
  let activeClass = inactiveClass + " ";

  const menu = props.menu;

  return (
    <div className={"w-auto self-center"}>
      <button
        onClick={() => onClickLink()}
        className={`flex flex-row space-x-2 px-4 py-2 items-center group ${isComponentVisible ? activeClass : inactiveClass}`}
      >
        {props.image && <img src={props.image} alt="" />}
        {props.icon && <FontAwesomeIcon icon={props.icon} size={"x"} />}
        {props.noSports && (
          <div className="flex items-center">
            <span className="hidden xl:flex text-white text-opacity-40 h-3 overflow-hidden">|</span>

            <img
              className={`rounded-full group-hover:border-white group-hover:border-opacity-8 hover:border-4 xl:ml-4 w-[42px] h-[42px] object-contain ${
                isComponentVisible ? "border-white border-opacity-8 border-4" : "border-white border-opacity-0 border-4"
              }`}
              src={props.profilePictureUrl || AvatarIcon}
              alt=""
              width="42"
              height="42"
            />

            <span className="hidden xl:flex text-white text-base font-semibold ml-1">{props.title}</span>
          </div>
        )}
        {props.menu === undefined && <p className="text-base font-semibold">{props.title}</p>}
        {props.menu && !props.noSports && (
          <div className={"flex flex-row space-x-1 self-center align-middle"}>
            <p className={"text-white text-base font-semibold"}>
              <span>{props.title}</span>
            </p>
          </div>
        )}
      </button>

      {isComponentVisible && (
        <div
          ref={ref}
          style={{ backgroundImage: "url('" + BgNavBar + "')" }}
          className="absolute z-50 top-16 left-0 w-full bg-white focus:outline-none bg-cover bg-no-repeat bg-center"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className={`shadow-sm flex flex-row z-50 ${props.header ? "" : "pt-2"} ${props.footer ? "" : "pb-2"}`} role="none">
            <div className="flex justify-between xl:space-x-12 xl:mx-auto mx-6 p-8 w-full xl:w-11/12">
              <div className="flex flex-col justify-center space-y-4 w-3/12">
                <h2 className="text-[#056155] font-bold font-headers text-[40px] leading-7 m-0 max-w-[240px] leading-10">
                  {props.extendedTitle}
                </h2>
                <p className="text-sm text-black-4D max-w-[240px]">{props.tagLine}</p>
              </div>

              {props.boxLink && !props.noSports && (
                <div className="hidden xl:flex xl:flex-col xl:justify-between gap-2">
                  <div
                    className={`flex flex-col justify-center bg-no-repeat bg-center bg-cover w-[343px] ${
                      props.secondaryBoxLink ? "" : "h-[162px]"
                    } rounded-lg`}
                    style={{ backgroundImage: "url('" + props.boxBg + "')" }}
                  >
                    <div className="flex flex-col space-y-2 justify-between align-middle p-5">
                      <p
                        className={`font-semibold font-headers text-white ${props.boxTagLine ? "text-2xl leading-7" : "text-xl leading-5"}`}
                      >
                        {props.boxTitle}
                      </p>
                      {props.boxTagLine && <p className="w-7/12 font-medium text-white text-sm">{props.boxTagLine}</p>}
                      <div className="flex cursor-pointer">
                        <a href={props.boxLink}>
                          <div className="bg-white px-4 py-1 rounded-full text-[#1A1A1A] text-sm font-semibold flex flex-row space-x-1">
                            <p>{props.boxAction || "View"}</p>
                            <div className="w-4 self-center">
                              <ChevronRightIcon />
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  {props.secondaryBoxLink && (
                    <div
                      className="flex flex-col justify-center bg-no-repeat bg-center bg-cover w-[343px] h-[100px] rounded-lg"
                      style={{ backgroundImage: "url('" + props.secondaryBoxBg + "')" }}
                    >
                      <div className="flex flex-col space-y-2 justify-between align-middle p-5 h-full">
                        <p className="font-semibold font-headers text-white text-xl leading-5">{props.secondaryBoxTitle}</p>
                        {props.secondaryBoxTagLine && <p className="w-7/12 font-medium text-white text-sm">{props.secondaryBoxTagLine}</p>}
                        <div className="flex cursor-pointer">
                          <a href={props.secondaryBoxLink}>
                            <div className="bg-white px-4 py-1 rounded-full text-[#1A1A1A] text-sm font-semibold flex flex-row space-x-1">
                              <p>{props.secondaryBoxAction || "View"}</p>
                              <div className="w-4 self-center">
                                <ChevronRightIcon />
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {!props.noSports && (
                <div
                  className={`${
                    menu?.footballItems && menu?.basketballItems ? "w-7/12" : "w-3/12"
                  } flex flex-row self-start justify-end space-x-12`}
                >
                  {menu?.footballItems && (
                    <div className="flex flex-col w-full max-w-[250px]">
                      <div className={"flex flex-row space-x-2 pb-3 border-b border-[#B3B3B3] w-full"}>
                        <img src={SportsSoccerIcon} alt="" />
                        <p className={"font-bold text-[#056155] text-xl"}>Football</p>
                      </div>

                      <div className={"flex flex-col mt-3 space-y-2 font-semibold text-sm"}>
                        {menu?.footballItems.map((item) => {
                          return (
                            <div className="flex items-center gap-1">
                              <a className="text-black-4D hover:text-[#1A1A1A]" href={item.href}>
                                {item.label}
                              </a>
                              {item?.isNew && <NewTag />}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {menu?.basketballItems && (
                    <div className="flex flex-col w-full max-w-[250px]">
                      <div className={"flex flex-row space-x-2 pb-3 border-b border-[#B3B3B3] w-full"}>
                        <img src={SportsBasketballIcon} alt="" />
                        <p className={"font-bold text-[#056155] text-xl"}>Basketball</p>
                      </div>

                      <div className={"flex flex-col mt-3 space-y-2 font-semibold text-sm"}>
                        {menu?.basketballItems.map((item) => {
                          return (
                            <div className="flex items-center gap-1">
                              <a className="text-black-4D hover:text-[#1A1A1A]" href={item.href}>
                                {item.label}
                              </a>
                              {item?.isNew && <NewTag />}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}

                  {menu?.baseballItems && (
                    <div className="flex flex-col w-full max-w-[250px]">
                      <div className="flex flex-row space-x-2 pb-3 border-b border-[#B3B3B3] w-full">
                        <img src={SportsBaseballIcon} alt="" />
                        <p className="font-bold text-[#056155] text-xl">Baseball</p>
                      </div>

                      <div className="flex flex-col mt-3 space-y-2 font-semibold text-sm">
                        {menu?.baseballItems.map((item) => {
                          return (
                            <div className="flex items-center gap-1">
                              <a className="text-black-4D hover:text-[#1A1A1A]" href={item.href}>
                                {item.label}
                              </a>
                              {item?.isNew && <NewTag />}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {props.noSports && (
                <div className="hidden lg:flex lg:flex-row xl:space-x-8 1.5x:space-x-12">
                  <div className="flex flex-col justify-center w-6/12">
                    <div
                      className="flex flex-col justify-center bg-no-repeat bg-center bg-cover w-[343px] h-[162px]"
                      style={{ backgroundImage: "url('" + props.boxBg + "')" }}
                    >
                      <div className="flex flex-col space-y-2 justify-center align-middle p-5">
                        <p className="font-semibold font-headers text-white text-2xl leading-7">{props.boxTitle}</p>
                        <p className="w-7/12 font-medium text-white text-sm">{props.boxTagLine}</p>
                        <div className="flex cursor-pointer">
                          <a href={props.boxLink}>
                            <div className="bg-white px-4 py-1 rounded-full text-[#1A1A1A] text-sm font-semibold flex flex-row space-x-1">
                              <p>View</p>
                              <div className="w-4 self-center">
                                <ChevronRightIcon />
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="hidden xl:flex xl:flex-col xl:justify-center xl:w-6/12">
                    <div
                      className="flex flex-col justify-center bg-no-repeat bg-center bg-cover w-[343px] rounded-xl h-[162px]"
                      style={{
                        backgroundImage: "url('" + BlueBgMembership + "')",
                      }}
                    >
                      <div className="flex flex-col space-y-2 justify-center align-middle p-5">
                        <div className="flex flex-row space-x-2">
                          <p className="font-semibold font-headers text-white text-2xl leading-7">Membership</p>
                          <MembershipBadge tier={props.tier} />
                        </div>
                        <p className="w-10/12 font-medium text-white text-sm">Take your Sorare experience to the next level.</p>
                        <div className="flex cursor-pointer">
                          <a href="/profile?target=subscription">
                            <div className="bg-white px-4 py-1 rounded-full text-[#1A1A1A] text-sm font-semibold flex flex-row space-x-1">
                              {t1OrBelow(props.tier) && <p>Upgrade</p>}
                              {isTier2(props.tier) && <p>My membership</p>}
                              <div className="w-4 self-center">
                                <ChevronRightIcon />
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {props.noSports && (
                <div className="flex flex-col w-4/12 max-w-[250px]">
                  <div className="flex justify-between pb-3 border-b border-[#B3B3B3] w-full">
                    <p className="font-bold text-[#056155] text-xl">My account</p>
                    <ThemeButton />
                  </div>

                  <div className="flex flex-col mt-3 space-y-2 items-start font-semibold text-sm text-black-4D">
                    {menu?.map((item) => {
                      return (
                        <a className={`flex ${item.tag ? "space-x-2" : ""}`} href={item.href}>
                          <span>{item.label}</span>
                          {item.tag && (
                            <span className="flex items-center bg-[#0AC2AA] text-white text-xs rounded-full px-2 py-0.5">{item.tag}</span>
                          )}
                        </a>
                      );
                    })}
                    <SideBarUserFooter />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default withRouter(SidebarItem);
